import MiniCard from "components/DynamicComponent/atoms/MiniCard"

export default function MiniCardSection({ blok }) {
  return blok?.cards?.length > 0 ? (
    <section className="isolate z-10 px-6 py-12 lg:py-16">
      {blok?.heading && blok?.heading !== "" ? (
        <h2 className="mx-auto pb-6 text-center font-medium text-teal-dark">{blok?.heading}</h2>
      ) : null}
      <div className="relative mx-auto grid max-w-screen-xl place-items-stretch gap-7 sm:grid-cols-2 lg:grid-cols-3">
        {blok?.cards.map((card) => (
          <MiniCard {...card} key={card.heading} />
        ))}
      </div>
    </section>
  ) : null
}
